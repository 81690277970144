#about {
  height: 100vh;
  width: 100vw;
}

#aboutDesc {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto Mono", monospace;
}

#aboutImg {
  margin-top: 10px;
  max-height: 600px;
  height: 100%;
  width: auto
}

#aboutImageContainer {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#aboutPar {
  color: white;
  font-size: 20px;
  padding-left: 30px;
  text-align: left;
  padding-bottom: 25px;
}

#aboutTitle {
  color: white;
  font-size: 20px;
  padding-left: 30px;
  padding-top: 40px;
  padding-bottom: 15px;
}

.techStack {
  color: rgb(255, 255, 255);
}

#techStackContainer {
  padding-left: 30px;
}

#techStackTitle {
  color: white;
  font-size: 20px;
  padding-left: 30px;
  padding-top: 50px;
  margin-bottom: 15px;
}

.techTitles {
  color: white;
}

@media (max-width: 480px) {
  #about {
    height: 100%;
    width: 100vw;
  }

  #aboutImageContainer {
    display: none;
  }

  #aboutPar {
    font-size: 15px;
    text-align: center;
    margin-left: 5px;
    margin-right: 25px;
  }

  #clickMessage {
    font-size: 15px;
  }

  #aboutTitle {
    font-size: 15px;
    padding-left: 5px;
    padding-bottom: 20px;
  }

  #techStackContainer {
    padding-left: 0px;
  }

  #techStackTitle {
    padding-left: 0px;
  }
}
