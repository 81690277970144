#scrollButton {
  position: fixed;
  width: 100%;
  left: 47%;
  bottom: 70px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: rgb(255, 255, 255);
}

@media (max-width: 480px) {
  #scrollButton {
    left: 40%;
  }
}