#imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.canva {
  color: white;
}

.carousel-control-prev {
  width: 5%;
}

.carousel-control-next {
  width: 5%;
}

.git {
  color: rgb(255, 255, 255);
}

#languageIconContainer {
  padding-top: 30px;
}

#mainCarousel {
  height: 90vh;
}

#projectSlideContainer {
  width: 100%;
  height: 100vh;
  margin-top: 30px;
}

#slides {
  height: 100%;
}

.slideTitles {
  padding-bottom: 30px;
}

.projLangIcon {
  font-size: 50px;
  margin-right: 50px;
}

.projLink {
  color: white;
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 25px;
}

#projLinkContainer {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#vvImage {
  padding-left: 40px;
  padding-top: 100px;
  height: 100%;
}

#vvText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 80px;
  color: white;
  font-family: "Space Mono", monospace;
}

#projectsTitle {
  padding-top: 20px;
  color: white;
  font-family: "Space Mono", monospace;
}

@media (min-width: 500px) {
  #swipe {
    display: none;
  }
}

@media (max-width: 480px) {
  .carousel-indicators {
    display: none;
  }

  #projectSlideContainer {
    height: 100%;
  }

  #mainCarousel {
    height: 100%;
  }

  #languageIconContainer {
    padding-top: 30px;
    width: 100vw;
  }

  .projectImages {
    width: 90vw;
  }

  #projLink {
    font-size: 20px;
  }

  .projLangIcon {
    margin-right: 20px;
    padding-top: 10px;
    margin-bottom: 10px;
    word-wrap: break-word;
  }

  .please {
    padding-bottom: 10px;
  }

  #projLinkContainer {
    padding-top: 0px;
  }

  .projParagraph {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  #swipe {
    font-size: 15px;
  }

  #vvImage {
    padding-top: 0;
    padding-left: 0;
  }

  #vvText {
    width: 100%;
    padding-right: 0px;
  }
}
