.divider {
  position: relative;
  margin-top: 90px;
  height: 1px;
}

.divider:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, rgb(141, 143, 147), transparent);
}

#footer {
  height: 10vh;
  width: 100vw;
  color:rgb(141, 143, 147);
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainContainer {
  height: 100vh;
  width: 100vw;

}

#pageContainer {
  background-image: url(../Images/poly.jpg);
  background-attachment: fixed;
}

#projects {
  height: 100vh;
  width: 100vw;
  padding-top: 3vh;
}

@media (max-width: 480px) {
  #mainContainer {
    width: 100vw;
    height: 100vh;
  }

  #projects {
    height: 100%;
    padding-top: 0;
  }
}
