#hi {
  font-size: 150px;
  margin-bottom: 0;
}

#intro {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Space Mono", monospace;
}

#introContainer {
  margin-left: 5vw;
}

#mobileImage {
  height: 240px;
  width: 160px;
  /* margin-top: 80%; */
  margin-top: 65%;
  margin-right: 25px;
  border-radius: 0%;
}


#name {
  font-size: 95px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

#title {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 0;
}

@media (min-width: 481px) {
  #mobileImageContainer {
    display: none;
  }
}

@media (max-width: 480px) {
  #hi {
    font-size: 95px;
    padding-top: 0px;
    margin-bottom: 10px;
  }

  #intro {
    top: 25%;
  }

  #name {
    font-size: 25px;
    padding-top: 10px;
  }

  #title {
    font-size: 15px;
  }
}
