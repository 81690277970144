#contact {
  height: 90vh;
  width: 100vw;
  padding-top: 10vh;
  font-family: "Space Mono", monospace;
}

.contactIcons {
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.2s ease-in-out;
}

.contactIcons:hover {
  transform: scale(1.3);
  transition: 0.2s ease-in-out;
  /* border-radius: 50%; */
  /* box-shadow: 0 10px 15px rgba(163, 162, 163, 0.4); */
}

#contactIconsContainer {
  padding-top: 20px;
}

#contactMessage {
  color: white;
  padding-top: 20px;
  font-size: 20px;
  padding-left: 85px;
  padding-right: 85px;
  /* font-family: 'Roboto Mono', monospace; */
}

#contactTitle {
  color: rgb(255, 255, 255);
}

#liLink {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 480px) {
  #contact {
    height: 100%;
    padding-top: 40px;
  }
  #contactMessage {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
  }
}
