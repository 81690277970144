.cardFront {
  font-size: 25px;
}

.cardStyle {
  width: 100%;
  height: 200px;
  padding-top: 20px;
  /* font-size: 25px; */
  border-radius: 15px;
  background-color: rgba(128, 128, 128, 0.164);
}

.cardBack {
  font-size: 15px;
}

.cardImages {
  height: 75px;
  margin-top: 15px;
}

@media (max-width: 480px) {
  .cardImages {
    margin-top: 25px;
  }

  .cardStyle {
    width: 90vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
