.sidebarContainer {
  height: 300px;
  width: 80px;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  margin-right: 20px;
  padding-bottom: 5px;
  transform: translateY(-40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow-x: hidden;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  border-radius: 10px 0px 0px 10px;
}

.sidebarContainer a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.2s ease-in-out;
}

.sidebarContainer a:hover {
  color: #f1f1f1;
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}

.main {
  margin-left: 160px;
  padding: 0px 10px;
}

@media (max-width: 480px) {
  .sidebarContainer {
    flex-direction: row;
    width: 100%;
    border: none;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    transform: none;
    height: 80px;
    top: 85%;
    overflow: hidden;
  }

  #linkedInIcon {
    height: 80px;
    width: 80px;
  }

  .sideIcons {
    height: 70px;
    width: 70px;
  }
}
