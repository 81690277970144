form {
  margin-top: 50px;
}

.formRow {
  margin: 10px 0 20px 0;
}

.formInput {
  padding: 15px 10px;
  background-color: transparent;
  box-shadow: 0 0 0 2px white;
  border: none;
  color: white;
}

.formInput:focus {
  background-color: transparent;
  outline: none;
  box-shadow: 0 0 0 2px white;
  color: white;
}

.formInput::placeholder {
  color: white;
  opacity: 1;
  font-family: 'Roboto Mono', monospace;
}

.formInput:focus::placeholder {
  color: rgb(153, 152, 152);
  opacity: 1;
}

.errorMessage {
  color: #f45532;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

#notifyToast {
  color: #fff;
  border-radius: 6px;
  width: 200px;
  margin-left: 75px;
  background-color: #21ce99;
}

.submit-btn {
  background-color: transparent;
  color: white;
  padding: 15px 50px;
  border: 2px solid white;
  border-radius: 10px;
  margin-bottom: 25px;
  font-family: 'Roboto Mono', monospace;
}

.submit-btn:hover {
  background-color: #21ce99;
  color: black;
  border: 2px solid #21ce99;
}

.submit-btn:disabled:hover {
  background-color: black;
  color: #21ce99;
  cursor: wait;
}

@media (max-width: 768px) {
  .submit-message {
    width: 125px;
    margin-left: 200px;
  }
}