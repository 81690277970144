.custom-navbar-toggler {
  border-color: white;
}

.landing-navbar-links {
  text-decoration: none;
  color: rgb(242, 233, 233);
  font-size: 20px;
  margin-left: 40px;
  margin-right: 40px;
  font-weight: bold;
  transition: 0.2s ease-in-out;
  font-family: "Roboto Mono", monospace;
}

.landing-navbar-links:hover {
  text-shadow: 3px 3px 3px rgba(151, 151, 151, 0.991);
  transform: scale(1.3);
  transition: 0.2s ease-in-out;
}

@media (min-width: 481px) {
  #navContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
    padding: 0;
    padding-top: 10vh;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 480px) {
  .landing-navbar-links {
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 5px;
    padding-bottom: 0px;
  }

  #navBarContainer {
    height: 100%;
    display: none;
  }

  #navContainer {
    margin: auto;
  }

  #responsive-navbar-nav {
    background-color: rgb(9, 9, 9);
  }
}
