#menuIcon {
  color: white;
  font-size: 45px;
}

#sideNavContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.sideNav {
  height: 30%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
}

.sideNav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #f8f5f5;
  display: block;
  transition: 0.3s;
  font-family: "Roboto Mono", monospace;
}

.sideNav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (min-width: 481px) {
  #sideNavContainer {
    display: none;
  }
}
  

@media screen and (max-height: 480px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 28px;
  }
}
